import { useApolloClient, useMutation } from "@apollo/client";
import { TOKEN } from "@monorepo/config";
import {
  CHANGE_PASSWORD_MUTATION,
  CONFIRM_FORGET_PASSWORD_MUTATION,
  REQUEST_FORGET_PASSWORD_MUTATION,
} from "@monorepo/data-access";

import { useHistory } from "react-router-dom";

export const useAuthToken = () => {
  const setAuthToken = (authToken) => {
    localStorage.setItem(TOKEN, authToken);
  };
  const removeAuthToken = () => {
    localStorage.removeItem(TOKEN);
  };
  const token = localStorage.getItem(TOKEN);

  const getToken = () => {
    return localStorage.getItem(TOKEN);
  };
  return { token, setAuthToken, removeAuthToken, getToken };
};

export const useLogout = () => {
  const apolloClient = useApolloClient();
  const { logoutWithoutApollo } = useLogoutWithoutApollo();

  const logout = async () => {
    await apolloClient.clearStore();
    await logoutWithoutApollo();
  };

  return {
    logout,
    logoutWithoutApollo,
  };
};

export const useLogoutWithoutApollo = () => {
  const { removeAuthToken } = useAuthToken();
  const history = useHistory();
  const logoutWithoutApollo = async () => {
    try {
      removeAuthToken();
      history?.push(`/login?redirectURL=${window.location.href}`);
    } catch (error) {
      console.log("logout error", error);
    }
  };

  return {
    logoutWithoutApollo,
  };
};

export const useChangePassword = () => {
  const [mutate, { loading }] = useMutation(CHANGE_PASSWORD_MUTATION);
  const handleChangePassword = async (params) => {
    await mutate({
      variables: params,
    });
  };

  return {
    loading,
    handleChangePassword,
  };
};
export const useRequestForgetPassword = () => {
  const [mutate, { loading }] = useMutation(REQUEST_FORGET_PASSWORD_MUTATION);
  const handleRequestForgetPassword = async (params) => {
    await mutate({
      variables: params,
    });
  };

  return {
    loading,
    handleRequestForgetPassword,
  };
};

export function useConfirmForgetPassword() {
  const [mutate, { loading }] = useMutation(CONFIRM_FORGET_PASSWORD_MUTATION);

  const handleConfirmForgetPassword = async (params) => {
    await mutate({
      variables: params,
    });
  };
  return {
    loading,
    handleConfirmForgetPassword,
  };
}
